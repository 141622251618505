import { Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showFiller = false;
  isShow = false;
  title = 'hackathon-glasswing';
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  constructor(private sharedService: SharedService) {}
  ngOnInit() {
    this.sharedService.setDrawer(this.drawer);
  }
  toggleDrawer() {
    this.sharedService.toggle();
  }
}
