import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import { Injectable, NgZone } from '@angular/core';
import { MsalInterceptor } from '@azure/msal-angular';
@Injectable()
export class HttpAuthInterceptor
  extends MsalInterceptor
  implements HttpInterceptor
{
  override intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = StorageService.getToken();
    if (token != null) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + StorageService.getToken(),
        },
      });
    }

    return next.handle(request);
  }
}
