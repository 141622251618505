<!-- <router-outlet></router-outlet> -->
<!-- <app-header></app-header>
<router-outlet></router-outlet> -->

<!-- Second -->
<div class="wrapper">
  <app-header></app-header>
  <mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="left-sidenav" mode="side" hasBackdrop="false">
      <div class="leftMenu" *ngIf="true">
        <app-left-menu></app-left-menu>
      </div>
    </mat-drawer>
    <div class="mainContainer">
      <router-outlet> </router-outlet>
    </div>
  </mat-drawer-container>
  <!-- <app-footer *ngIf="isShow"></app-footer> -->
</div>
