<div class="container pt-3 register-user" id="header">
  <div class="hea-sub-text">
    <div class="heading">Register</div>
    <div class="sub-text">
      Register as a partner with us and get non-stop loads and return loads.
    </div>
  </div>
  <div class="row pt-4">
    <div class="col-8">
      <form [formGroup]="registerForm">
        <div class="row pt-4 pb-4">
          <div class="col">
            <mat-form-field class="transfer-ipt w-70" appearance="fill">
              <input matInput placeholder="Name" formControlName="name" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="transfer-ipt w-70" appearance="fill">
              <input
                matInput
                placeholder="Phone No."
                formControlName="phone_no"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-4 pb-4">
          <div class="col">
            <mat-form-field class="transfer-ipt w-70" appearance="fill">
              <input matInput placeholder="DL No." formControlName="dl_no" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="transfer-ipt w-70" appearance="fill">
              <input
                matInput
                placeholder="Truck Registration No."
                formControlName="truck_registration_no"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="upload-div">
          <input
            type="file"
            accept="image/*"
            #file
            [ngStyle]="{ display: 'none' }"
            (change)="uploadFile($event)"
          />
          <p>Upload Driver Licence</p>
          <button
            mat-button
            (click)="file.click()"
            class="browse-btn"
            *ngIf="!imageSrc"
          >
            Browse
          </button>
        </div>
        <div class="uploaded-pic" *ngIf="imageSrc">
          <img [src]="imageSrc" alt="" />
          <div class="overlay" (click)="file.click()">
            <mat-icon class="icon">photo_camera</mat-icon>
          </div>
        </div>
        <div class="terms pt-4">
          <mat-checkbox
            [checked]="acceptTermsAndCondition"
            (change)="setTerms($event.checked)"
            color="primary"
            >Yes, I accept Load Board terms and conditions, and give my consent
            for receiving calls</mat-checkbox
          >
        </div>
        <button
          mat-button
          class="register-btn mt-2 pl-2 pr-2"
          [ngClass]="
            acceptTermsAndCondition && registerForm.valid ? '' : 'disabled'
          "
          [disabled]="!acceptTermsAndCondition && registerForm.valid"
        >
          Register
        </button>
      </form>
    </div>
    <div class="col-4"></div>
  </div>
</div>
