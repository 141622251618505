import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'book-now-dialog',
  templateUrl: 'book-now.modal.html',
})
export class BookNowDialog {
  message: string = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  booknowForm!: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<BookNowDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {
    this.booknowForm = this.fb.group({
      source_city: ['', Validators.required],
      destination_city: ['', Validators.required],
      numberOfTrucks: ['', Validators.required],
      truck_type_cd: ['', Validators.required],
      materialType: ['', Validators.required],
      loadingTime: ['', Validators.required],
    });
    if (data) {
      this.message = data.message || this.message;
      if (data.formDetails) {
        this.booknowForm.setValue(data);
      }
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }
  ngOnInit(): void {
    this.sharedService.formDetails.subscribe((value: any) => {
      console.log('🚀 ~ value', value);
      if (value.source_city) {
        this.booknowForm.controls['source_city'].patchValue(value.source_city);
      }
      if (value.destination_city) {
        this.booknowForm.controls['destination_city'].patchValue(
          value.destination_city
        );
      }
      if (value.truck_type_cd) {
        this.booknowForm.controls['truck_type_cd'].patchValue(
          value.truck_type_cd
        );
      }
      if (value.numberOfTrucks) {
        this.booknowForm.controls['numberOfTrucks'].patchValue(
          value.numberOfTrucks
        );
      }
    });
  }

  bookNow() {
    if (this.booknowForm.valid) {
      this.dialogRef.close();
      let formDetails = this.booknowForm.value;
      // const dialog = this.dialog.open(BookingConfirmed, {
      //   width: '50vw',
      //   maxWidth: '50vw',
      //   panelClass: ['confirmedclass'],
      //   data: formDetails
      // });
      // dialog.afterClosed().subscribe((result) => {
      //   this.sharedService.setSearchFormDetails({});
      //   this.sharedService.resetSearchForm.next(true);
      //   // this.animal = result;
      // });
    } else {
      alert('Please fill all the truck request fields');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
