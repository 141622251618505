import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../core/services/http-service.service'

import { ResponseModel } from '../../core/model/response';
@Injectable()
export class MapDetailService {
  constructor(private serviceHttp: HttpService) { }
  getTrucksPopularLane(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/trucksPopularLane', 'POST', 0, data);
  }
  getTrucksFromSource(data: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/trucksFromSource', 'POST', 0, data);
  }
  getTucksCurrentLocation(data: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/trucksCurrentLocation', 'POST', 0, data);
  }
  search(str:string ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/search', 'POST', 0, {search:str });
  }
  getTripeDataById(id:any): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trips/'+ id, 'GET');
  }
  addTrip(data: any): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('trips', 'POST', 0, data);
  }
  updateTrip(data: any): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('trips/' + data.id, 'PUT', 0, data);
  }
}
