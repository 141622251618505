<h2
  matDialogTitle
  style="
    color: #ff9933;
    display: flex;
    justify-content: start;
    font-family: 'Poppins';
  "
>
  Booking Form
</h2>

<mat-dialog-content style="margin-bottom: 15px; overflow: hidden">
  <form id="booknow" [formGroup]="booknowForm">
    <div class="row book-now-form-row">
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">Origin</label>
        <mat-form-field class="transfer-ipt" appearance="fill">
          <input
            matInput
            placeholder="Load From"
            readonly="true"
            [disabled]="true"
            style="color: rgba(51, 51, 51, 0.6)"
            formControlName="source_city"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">Destination</label>
        <mat-form-field class="transfer-ipt" appearance="fill">
          <input
            matInput
            placeholder="Load To"
            readonly="true"
            style="color: rgba(51, 51, 51, 0.6)"
            formControlName="destination_city"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row book-now-form-row">
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">No. of Trucks</label>
        <mat-form-field class="transfer-ipt" appearance="fill">
          <input
            matInput
            placeholder="No of trucks"
            readonly="true"
            style="color: rgba(51, 51, 51, 0.6)"
            formControlName="numberOfTrucks"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">Truck Type</label>
        <mat-form-field class="transfer-ipt" appearance="fill">
          <input
            matInput
            placeholder="Truck Types"
            style="color: rgba(51, 51, 51, 0.6)"
            readonly="true"
            formControlName="truck_type_cd"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row book-now-form-row">
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">Material Type</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="materialType">
            <mat-option value="Truck Type"> Finished Goods </mat-option>
            <mat-option> Cement </mat-option>
            <mat-option> Electronics </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <label for="">Loading Time</label>
        <mat-form-field appearance="fill" class="css">
          <!-- <mat-label>Choose a date</mat-label> -->
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="loadingTime"
          />
          <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    class="book-now-btn d-flex justify-content-end"
    (click)="bookNow()"
  >
    Book
  </button>
</mat-dialog-actions>
