import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BookNowDialog } from 'src/app/layout/dialogs/book-now-dialog/book-now.modal';
import { DialogService } from 'src/app/layout/dialogs/services/dialog.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MapDetailService } from './map-detail.service';
import * as appGlobals from '../../core/globalfunctions';
import { ActivatedRoute, Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { indiaMapBounds } from '../../core/constants';
import { CallDetailsModal } from 'src/app/layout/dialogs/call-details/call-details.modal';

@Component({
  selector: 'app-map-detail',
  templateUrl: './map-detail.component.html',
  styleUrls: ['./map-detail.component.scss'],
  providers: [MapDetailService, DialogService],
})
export class MapDetailComponent implements OnInit {
  origin = { lat: 19.076, lng: 72.8777 };
  destination = { lat: 28.6139, lng: 77.209 };
  // public lat = 19.076;
  // public lng = 72.8777;
  zoom = 7;
  map: any;
  lat: number = indiaMapBounds['center_lat']; // 22.9851264; // 28.5246
  lng: number = indiaMapBounds['center_lng']; // 77.4319773; // 77.2066;
  layoutType = 'markers';
  bounds: any;
  public showPath: boolean = false;
  public finalStep: boolean = false;
  public firstSearch: boolean = false;
  public secondSearch: boolean = false;
  formDetails: any;
  numberOfTrucks: number = 0;
  markers = new Array<any>();

  popularLanesDetails = [
    { destination_city: 'Mumbai', source_city: 'Delhi', trips_count: '250', registration_no: 'HR98' },
  ];
  truckTypesDetails = [
    {
      source_city: 'Mumbai',
      destination_city: 'Delhi',
      truck_type_cd: 'Truck Type',
      trips_count: '250',
      registration_no: 'HR98'
    },
  ];
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private mapDetailService: MapDetailService,
    private _dialogService: DialogService,
    private router: Router,
    protected route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader
  ) {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      });
    }
  }

  ngOnInit(): void {
    this.sharedService.setRouterOutlet(this.router.url);

    this.sharedService.finalStep.subscribe((res) => {
      this.finalStep = res;
    });
    this.sharedService.resetSearchForm.subscribe((res) => {
      if (res) {
        this.showPath = false;
        this.finalStep = false;
        this.firstSearch = false;
        this.secondSearch = false;
      }
    });
    this.sharedService.formDetails.subscribe((res) => {
      console.log(res);
      if (res.source_city) {
        this.firstSearch = true;
      }

      if (res.destination_city) {
        this.secondSearch = true;
      }

      this.formDetails = res;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: res,
        queryParamsHandling: '',
      });
      console.log('naviggation order');
      console.log('sending get tresponse after navigation');

      // this.getResponse();
    });
    this.route.queryParams.subscribe((aData) => {
      if (aData && Object.keys(aData).length > 0) {
        this.fillSearchFormFromQueryParams(aData);
      }
      this.getResponse();
      // multiselect issue
    });
  }
  fillSearchFormFromQueryParams(aData: any) {
    this.sharedService.setSearchFormDetails(aData);
  }
  getTruckTypeValue(key: any) {
    return appGlobals.getTruckTypeValue(key);
  }

  goToPopularLanes(data: any) {
    let value = {
      source_city: data.source_city,
      destination_city: data.destination_city,
      sent_list: "trucks"
    };
    this.sharedService.setSearchFormDetails(value);
    this.showPath = true;
    this.firstSearch = true;
    this.secondSearch = true;
  }

  setTruckFrom(data: any) {
    let value = {
      source_city: data.source_city,
    };
    this.firstSearch = true;
    this.sharedService.setSearchFormDetails(value);
  }
  setTruckLists(data: any) {
    let value = {
      source_city: data.source_city,
      destination_city: data.destination_city
    };
    this.sharedService.setSearchFormDetails(value);

  }

  selectTruckType(data: any) {
    console.log('select truck type');
    let value = {
      source_city: data.source_city,
      destination_city: data.destination_city,
      truck_type_cd: data.truck_type_cd,
      trips_count: data.trips_count,
    };
    this.firstSearch = true;
    this.sharedService.setSearchFormDetails(value);
    this.secondSearch = true;
  }

  resetSearch() {
    let value = {
      source_city: '',
      destination_city: '',
      truck_type_cd: '',
      numberOfTrucks: 0,
    };
    this.sharedService.setSearchFormDetails(value);
    this.sharedService.resetSearchForm.next(true);
    this.showPath = false;
    this.firstSearch = false;
  }

  back() {
    let value = {
      source_city: '',
      destination_city: '',
      truck_type_cd: '',
    };
    this.sharedService.setSearchFormDetails(value);
    this.showPath = false;
    this.secondSearch = false;
    this.firstSearch = false;
  }

  mapClicked($event: MouseEvent) {
    //  console.log($event);
    // this.markers.push({
    //   location_lat: $event.coords.lat,
    //   location_long: $event.coords.lng,
    //   draggable: true,
    //   name: ''
    // });
    // this.markers.push(new Marker({
    //   location_lat: $event.coords.lat,
    //   location_long: $event.coords.lng,
    //   draggable: true,
    // }));
  }
  onDirectionResponse(event: any) {
    //  console.log(event);
    //  this.summaryHTML = '';
    //  if (event.routes && event.routes[0]) {
    //    let route = event.routes[0];
    //    // For each route, display summary information.
    //    for (var i = 0; i < route.legs.length; i++) {
    //      let routeSegment = i + 1;
    //      this.summaryHTML += '<b>Route Segment: ' + routeSegment +
    //        '</b><br>';
    //      this.summaryHTML += route.legs[i].start_address + ' to ';
    //      this.summaryHTML += route.legs[i].end_address + '<br>';
    //      this.summaryHTML += route.legs[i].duration.text + '<br>';
    //      this.summaryHTML += route.legs[i].distance.text + '<br><br>';
    //    }
    //  }
    // for(var i =0; i < this.markerOptions.waypoints.length; i++){
    //   this.markerOptions.waypoints[i].infoWindow = this.markerOptions.waypoints[i].infoWindow + ' ' + appGlobals.timeSince(new Date(this.markerOptions.waypoints[i].infoWindow));
    // }
  }

  onDirectionChange(event: any) {
    //  console.log(event);
  }
  openDialog() {
    let value = {
      numberOfTrucks: this.numberOfTrucks,
    };
    this.sharedService.setSearchFormDetails(value);
    let formDetails = this.sharedService.formDetails;

    let dialogRef = this.dialog.open(BookNowDialog, { data: formDetails });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openCallDetailDialog() {
    console.log('🚀 ~ openCallDetailDialog');
    let dialogRef = this.dialog.open(CallDetailsModal, { minWidth: '50vw' });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  obtainInfowindow(event: any) {
    //  console.log(event);
  }
  mapReady(event: any) {
    //  console.log(' map is ready');
    //  console.log(event);
    this.map = event;
    this.findBounds();
  }
  public findBounds() {
    this.mapsAPILoader.load().then(() => {
      if (this.markers) {
        this.bounds = new google.maps.LatLngBounds();
        let i = 0;
        console.log(this.markers);
        for (let marker of this.markers) {
          this.bounds.extend(
            new google.maps.LatLng(marker.location_lat, marker.location_long)
          );
        }

        if (this.markers.length == 0) {
          this.bounds.extend(
            new google.maps.LatLng(
              indiaMapBounds['sw_lat'],
              indiaMapBounds['sw_lng']
            )
          );
          this.bounds.extend(
            new google.maps.LatLng(
              indiaMapBounds['ne_lat'],
              indiaMapBounds['ne_lng']
            )
          );
        }

        this.map.fitBounds(this.bounds);
      }
    });
    return this.bounds;
  }
  getResponse(pageNo = 1) {
    this.mapDetailService
      .getTucksCurrentLocation(this.sharedService.details)
      .subscribe(
        (response) => {
          this.markers = response.data;
        },
        (error) => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error),
          });
        }
      );

    this.mapDetailService
      .getTrucksPopularLane(this.sharedService.details)
      .subscribe(
        (response) => {
          console.log(response.data);
          this.popularLanesDetails = response.data;
        },
        (error) => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error),
          });
        }
      );
    this.mapDetailService
      .getTrucksFromSource(this.sharedService.details)
      .subscribe(
        (response) => {
          console.log(response.data);
          this.truckTypesDetails = response.data;
          console.log('🚀 ~ this.truckTypesDetails ', this.truckTypesDetails);
        },
        (error) => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error),
          });
        }
      );
  }
}
