import { Dropdowns } from '../layout/dropdowns/dropdowns';
export class Constants {
  public static DROP_DOWN_LIST: Dropdowns[];
  public static DROP_DOWN_HASH: any[];
}
export const onlyAdmin = [10];
export const employee = [10, 1];
export const investor = [10, 1, 6];
export const partner = [10, 1, 5];
export const fleetowner = [10, 4];
export const driver = [10, 2];
export const customer = [10, 3];
export const admin_driver_fleetOwner_partner = [10, 1, 2, 4, 5];
export const admin_fleetOwner_partner = [10, 1, 4, 5];
export const admin_customer = [10, 1, 3];
export const investors = [6, 10, 1, 3];
export const indiaMapBounds = {
  id: '71',
  long_name: 'India',
  short_name: 'IN',
  center_lat: 20.593684,
  center_lng: 78.96288,
  sw_lat: 6.753516,
  sw_lng: 68.162386,
  ne_lat: 35.504475,
  ne_lng: 97.395555,
};
export type ScreenState = Readonly<{
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}>;
export const work_areas = {
  // '':  '',
  'BOOKING EXECUTIVE': 'BOOKING EXECUTIVE',
  'CT EXECUTIVE': 'CT EXECUTIVE', //tracking
  COORDINATOR: 'COORDINATOR', // webx cab be booking exec, .. should claim first,
  'FINANCE EXECUTIVE': 'FINANCE EXECUTIVE', // payment approval
  'BILLING EXECUTIVE': 'BILLING EXECUTIVE', // bill creation in webx. upload in admin
  KAM: 'KAM', // bill submission, collection followups
  'LR EXECUTIVE': 'LR EXECUTIVE', //dispatch, at times arrival
  'TRAFFIC EXECUTIVE': 'TRAFFIC EXECUTIVE', // placement
  ROH: 'ROH', // confirm trip, approval, manager
  'OPS HEAD': 'OPS HEAD', // manager of ROH
  MANAGEMENT: 'MANAGEMENT', // all managers.. their org
  OTHER: 'OTHER',
  /*
'BOOKING EXECUTIVE' => 'BOOKING EXECUTIVE', // booking and placement?
        'CT EXECUTIVE' => 'CT EXECUTIVE', //tracking
        'COORDINATOR' => 'COORDINATOR', // webx cab be booking exec, .. should claim first,
        'FINANCE EXECUTIVE' => 'FINANCE EXECUTIVE', // payment approval
        'BILLING EXECUTIVE' => 'BILLING EXECUTIVE', // bill creation in webx. upload in admin
        'KAM' => 'KAM', // bill submission, collection followups
        'LR EXECUTIVE' => 'LR EXECUTIVE', //dispatch, at times arrival
        'TRAFFIC EXECUTIVE' => 'TRAFFIC EXECUTIVE', // placement
        'ROH' => 'ROH', // confirm trip, approval, manager
        'OPS HEAD' =>  'OPS HEAD', // manager of ROH
        'MANAGEMENT' => 'MANAGEMENT' // all managers.. their org
*/
};
