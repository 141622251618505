import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public details: any = {};
  public formDetails = new BehaviorSubject<any>(this.details);
  public finalStep = new BehaviorSubject<boolean>(false);
  public resetSearchForm = new BehaviorSubject<boolean>(false);
  public showSearch = new BehaviorSubject<boolean>(true);
  private drawer!: MatDrawer;

  constructor() {}

  setSearchFormDetails(data: any) {
    this.details = { ...this.details, ...data };
    this.formDetails.next(this.details);
  }

  public setRouterOutlet(data: string) {
    if (data.startsWith('/register')) {
      this.showSearch.next(false);
    } else {
      this.showSearch.next(true);
    }
  }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  close(): void {
    this.drawer.close();
  }
}
