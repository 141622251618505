import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { AuthenticatorService } from '../../core/services/authenticator.service';
import { Users } from 'src/app/manage/users/users';
import { StorageService } from '../../core/services/storage.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  IsExpanded!: boolean;
  displayIcon!: boolean;
  customCollapsedHeight: string = '50px';
  customExpandedHeight: string = '50px';
  user!: Users;
  user_id: number = 0;
  company_name!: string;
  avatar_url = 'assets/img/user.png';
  isShow: boolean = true;
  panelOpenState = false;
  constructor(private router: Router, private sharedService: SharedService) {
    // this.displayIcon = false;
  }

  ngOnInit(): void {
    // this.setUserParameters();
  }

  // setUserParameters() {
  //   this.user = StorageService.getUser();
  //   // this.user_id = (this.user && this.user!= null) ? this.user.id : 0;
  //   if(this.user  && this.user!= null) {
  //     if(this.user.avatar_location) {
  //       this.avatar_url = this.user.avatar_location;
  //     }
  //     else{
  //       this.avatar_url = 'https://ui-avatars.com/api/?rounded=false&background=EAE7F9&name=' + this.user.name;
  //     }

  //   }
  //   this.company_name = StorageService.getItem('company_name');
  //   if(this.user && this.user!= null && (!this.company_name) || this.company_name == null){
  //     if(this.user.work_area_cd && this.user.work_area_cd[0]){
  //       this.company_name = this.user.work_area_cd[0];
  //     }
  //   }

  //   this.commonService.castLogin$.subscribe(isShow=>{
  //     setTimeout(()=>{
  //       this.isShow = isShow;
  //     });
  //   });
  // }

  toggleDrawer() {
    // this.authenticatorService.selectedMenuName.next('demo');
    // localStorage.setItem('selectedMenu', "dummy Name");
    this.sharedService.toggle();
    // console.log("toggle works . . .")
  }

  checkRole(arr: any) {
    // // console.log(arr);
    // // console.log(StorageService.checkRole(arr));
    // return StorageService.checkRole(arr);
  }
  logout() {
    // // console.log('header logout');
    // this.authenticatorService.signOut();
    // this.commonService.changeHeader(false);
    // this.router.navigate(['user/login']);
  }
}
