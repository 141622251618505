<div class="left-menu-width">
  <div>
    <ul>
      <li class="link-li">
        <a [routerLink]="['/main']" routerLinkActive="active">Truck Board</a>
      </li>
      <li class="link-li">
        <a [routerLink]="['/register']" routerLinkActive="active"
          >Registration</a
        >
      </li>
    </ul>
  </div>
</div>
