import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-register-driver',
  templateUrl: './register-driver.component.html',
  styleUrls: ['./register-driver.component.scss'],
})
export class RegisterDriverComponent implements OnInit {
  public imageSrc: any = null;
  public file: any;
  public registerForm!: FormGroup;
  public acceptTermsAndCondition: boolean = false;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.sharedService.setRouterOutlet(this.route.url);

    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      phone_no: ['', Validators.required],
      dl_no: ['', Validators.required],
      truck_registration_no: ['', Validators.required],
    });
  }
  setTerms(event: boolean) {
    console.log('event', event);
    this.acceptTermsAndCondition = event;
  }
  uploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.file = fileList[0];
      // this.profileForm.patchValue({
      //   photo: this.file,
      // });
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(this.file);

      // this.renderer.setStyle(Elemen)
      // console.log('FileUpload -> files', fileList);
    }
  }
}
