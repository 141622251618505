import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service'
import { CommonService } from '../services/common.service';
// import { AuthenticatorService } from '../services/authenticator.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private commonService:CommonService,
    private router:Router,
    // private authenticatorService: AuthenticatorService,
    ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if(StorageService.isLogin() === true){
        this.commonService.changeHeader(true);
        return true;
      }
      else {
        this.commonService.changeHeader(false);
     //  console.log('user not logged in auth guard ' );
        this.router.navigate(['user/login'], { queryParams: { returnUrl: state.url }});

      }
      return false;

  }
}
