import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  searchForm: FormGroup;
  showSearch = true;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private route: Router
  ) {
    this.searchForm = this.fb.group({
      source_city: [''],
      destination_city: [''],
      truck_type_cd: [''],
    });
  }

  ngOnInit(): void {
    this.sharedService.setRouterOutlet(this.route.url);
    this.sharedService.showSearch.subscribe((val) => {
      this.showSearch = val;
    });
    this.sharedService.formDetails.subscribe((value: any) => {
      // console.log('🚀 ~ value', value);
      this.searchForm.controls['source_city'].patchValue(value.source_city);
      this.searchForm.controls['destination_city'].patchValue(
        value.destination_city
      );

      this.searchForm.controls['truck_type_cd'].patchValue(value.truck_type_cd);
      // if (value.source_city) {
      // }
      // if (value.destination_city) {
      // }
      // if (value.truck_type_cd) {
      // }
    });
    this.searchForm.valueChanges.subscribe((res: any) => {
      if (
        res.truck_type_cd != null &&
        res.source_city != null &&
        res.destination_city != null
      ) {
        if (
          res.truck_type_cd.length &&
          res.source_city.length &&
          res.destination_city.length
        ) {
          this.sharedService.finalStep.next(true);
        } else {
          this.sharedService.finalStep.next(false);
        }
        // this.sharedService.setSearchFormDetails(res);
      }
    });
    this.sharedService.resetSearchForm.subscribe((res) => {
      if (res) {
        this.searchForm.reset();
        this.settruckTypesCd(null);
        this.sharedService.setSearchFormDetails(this.searchForm.value);
        this.sharedService.setSearchFormDetails({ numberOfTrucks: 0 });
        // console.log('reset', this.searchForm.value);
      }
    });
  }
  submitSearch() {
    this.sharedService.setSearchFormDetails(this.searchForm.value);
  }
  settruckTypesCd(aData: any) {
    this.searchForm.patchValue({ truck_type_cd: aData });
  }
  toggleDrawer() {
    this.sharedService.toggle();
  }
}
