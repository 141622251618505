<h2
  matDialogTitle
  style="
    color: #ff9933;
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
  "
>
  Call Details
</h2>

<mat-dialog-content style="margin-bottom: 15px; overflow: hidden">
  <div class="confirmed-modal">
    <!-- <div class="first">Booking Confirmed</div> -->
    <div class="second">Please use these details below to call the owner.</div>

    <div class="d-flex justify-content-around pl-2 pr-2 pt-3 pb-2">
      <div>Owner Name : Rajesh Babu</div>
      <div>Mobile No. : 9134859304</div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-button
    class="book-now-btn d-flex justify-content-center"
    (click)="onConfirmClick()"
  >
    Close
  </button>
</mat-dialog-actions>
