import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';

// import {MatNativeDateModule} from '@angular/material';

let materialmodule = [
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatGridListModule,
  MatCardModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatRippleModule,
  MatSidenavModule,
  MatTooltipModule,
  MatTabsModule,
  MatSelectModule,
  MatStepperModule,
  MatSnackBarModule,
  MatListModule,
  MatExpansionModule,
  MatMenuModule,
  MatSliderModule,
  MatDatepickerModule,
];
@NgModule({
  imports: [materialmodule],
  providers: [MatDatepickerModule, MatNativeDateModule],
  exports: [materialmodule, MatDatepickerModule, MatNativeDateModule],
})
export class MaterialModule {}
