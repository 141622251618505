<div *ngIf="flag == 1 && isArray(message)">
  <h2 mat-dialog-title *ngIf="title"> {{title}} </h2>
  <mat-dialog-content>
    <ul class="td-dialog-message">
      <li *ngFor="let x of message">
        {{x}}
      </li>
    </ul>
    <!-- <span class="td-dialog-message">
          </span> -->
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="close()"> {{closeButton}} </button>
  </mat-dialog-actions>
</div>
<div *ngIf="flag !=1 || !isArray(message)">
  <h2 mat-dialog-title *ngIf="title"> {{title}} </h2>
  <mat-dialog-content>
    <span class="td-dialog-message" [innerHTML]="message"></span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="close()"> {{closeButton}} </button>
  </mat-dialog-actions>
</div>
