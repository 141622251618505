import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapDetailComponent } from './main/map-detail/map-detail.component';
import { RegisterDriverComponent } from './main/register-driver/register-driver.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    component: MapDetailComponent,
  },
  {
    path: 'register',
    component: RegisterDriverComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
