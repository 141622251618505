import {
  FormGroup,
  FormControl,
  ValidationErrors,
  AbstractControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { Constants } from './constants';
import {
  debounceTime,
  filter,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ResponseModel } from './model/response';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { DatePipe } from '@angular/common';
import { DataSource } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
export const SNACKBAR_TIMEOUT = 3000;
export const gst_pattern =
  '[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}';
export const ifsc_pattern = '^[A-Za-z]{4}[a-zA-Z0-9]{7}$';
export const pan_pattern = '[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}';
export function toUpperCase(event: any) {
  if (event.srcElement.value != null) {
    event.srcElement.value = event.srcElement.value.toUpperCase();
  }
}
export function markFormGroupTouched(formGroup: any) {
  if (formGroup.controls) {
    const keys = Object.keys(formGroup.controls);
    for (let i = 0; i < keys.length; i++) {
      const control = formGroup.controls[keys[i]];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        markFormGroupTouched(control);
      } else if (control instanceof FormArray) {
        markFormGroupTouched(control);
      }
    }
  } else {
    if (formGroup instanceof FormControl) {
      // is a FormControl
      formGroup.markAsTouched();
    }
  }
}

export function getFormValidationErrors(formGroup: any) {
  const errorMessages = new Array();
  if (formGroup.controls) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = formGroup.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            if (keyError == 'required') {
              errorMessages.push(snakeCaseToTitle(key) + ' is required.</li>');
            } else {
              errorMessages.push(
                snakeCaseToTitle(key) + ' is invalid. ' + keyError + '</li>'
              );
            }
            // errorMessages.push(

            //   'Form group name' +
            //   getControlName(formGroup) +
            //   ' ' +
            //   +'Key control: ' +
            //   key +
            //   ', keyError: ' +
            //   keyError +
            //   ', err value: ',
            //   controlErrors[keyError]
            // );
          });
        }
      } else if (control instanceof FormGroup) {
        const amessages = getFormValidationErrors(control);
        if (amessages.length > 0) {
          errorMessages.push(amessages);
        }
      } else if (control instanceof FormArray) {
        const amessages = getFormValidationErrors(control);
        if (amessages.length > 0) {
          errorMessages.push(amessages);
        }
      }
    });
    console.log(errorMessages);
  }
  return errorMessages;
}
export function titleCase(aSentence: any) {
  let sentence = aSentence.toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
}
export function snakeCaseToTitle(aField: string) {
  const newField = aField.replace('_', ' ');
  return titleCase(newField);
}
export function getFormValidationErrorsDisplayMessage(formGroup: any) {
  const errors = getFormValidationErrorsArray(formGroup);
  let result = '';
  if (errors && errors.length > 0) {
    result = result + '<ul>';
    errors.forEach((element) => {
      if (element.keyError == 'required') {
        result =
          result +
          '<li>' +
          snakeCaseToTitle(element.keyControl) +
          ' is required.</li>';
      } else {
        result =
          result +
          '<li>' +
          snakeCaseToTitle(element.keyControl) +
          ' is invalid: ' +
          element.keyError +
          '</li>';
      }
    });
    result = result + '</ul>';
  }
  return result;
}
export function getFormValidationErrorsArray(formGroup: any) {
  const errorMessages = new Array();
  if (formGroup.controls) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = formGroup.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            errorMessages.push({
              formGroup: getControlName(formGroup),
              keyControl: key,
              keyError: keyError,
              keyValue: controlErrors[keyError],
            });
          });
        }
      } else if (control instanceof FormGroup) {
        const amessages = getFormValidationErrors(control);
        if (amessages.length > 0) {
          errorMessages.push(amessages);
        }
      } else if (control instanceof FormArray) {
        const amessages = getFormValidationErrors(control);
        if (amessages.length > 0) {
          errorMessages.push(amessages);
        }
      }
    });
    //  console.log(errorMessages);
  }
  return errorMessages;
}
function getControlName(c: AbstractControl): string | null {
  // if (!c.parent) {
  //   return null;
  // }
  // const formGroup = c.parent.controls;
  // return Object.keys(formGroup).find((name:any) => c === formGroup[name]) || null;
  return null;
}

function getControlPath(c: AbstractControl, path: string): string | null {
  path = getControlName(c) + path;

  if (c.parent && getControlName(c.parent)) {
    path = '.' + path;
    return getControlPath(c.parent, path);
  } else {
    return path;
  }
}
// export function customEmailValidator(control: AbstractControl): {[key: string]: any} {
//   const emailError = Validators.email(control);
//   if (control.value && emailError) {
//       return {'email': {}};
//   }
//   return null;
// }

// export function customEmailValidator(
//   control: AbstractControl
// ): ValidationErrors {
//   if (!control.value) {
//     return null;
//   }

//   return Validators.email(control);
// }

export function selectCityRegion(event: any) {
  const allAddress = selectStreetLocation(event);
  return allAddress.city + ', ' + allAddress.state_short;
}
export function selectStreetLocation(event: any) {
  // console.log(event);
  const addressCompoments: any = [];
  event.address_components.forEach((element: any) => {
    element.types.forEach((type: any) => {
      addressCompoments[type] = element.long_name;
      if (type === 'administrative_area_level_1') {
        addressCompoments['state_short'] = element.short_name;
      }
    });
  });
  // console.log('got address new way');
  // console.log(addressCompoments);
  const aCountry = addressCompoments['country'];
  let aState = addressCompoments['administrative_area_level_1'] || '';

  let aDistrict = addressCompoments['administrative_area_level_2'] || '';
  if (aCountry === 'Bhutan') {
    aDistrict = aState;
    addressCompoments['state_short'] = aState;
    aState = 'Bhutan';
  }
  const aLocality = addressCompoments['locality'] || '';
  let aCity = aLocality;
  if (aCity === '') {
    if (aDistrict) {
      aCity = aDistrict;
    } else if (addressCompoments['route']) {
      aCity = addressCompoments['route'];
    }
  }
  // if(aDistrict !== '') {
  //   aCity  =  aCity +   ', '  + aDistrict;
  // }
  const aPincode = addressCompoments['postal_code'];
  const aLat = event.geometry.location.lat();
  const aLong = event.geometry.location.lng();

  let aStreetName = '';
  if (event.formatted_address) {
    const formatttedAddressArray = event.formatted_address.split(', ');
    let indexToRemove = formatttedAddressArray.indexOf(aCountry);
    if (indexToRemove > -1) {
      formatttedAddressArray.splice(indexToRemove);
    }
    indexToRemove = formatttedAddressArray.indexOf(aPincode);
    if (indexToRemove > -1) {
      formatttedAddressArray.splice(indexToRemove);
    }
    indexToRemove = formatttedAddressArray.indexOf(aState);
    if (indexToRemove > -1) {
      formatttedAddressArray.splice(indexToRemove);
    }
    indexToRemove = formatttedAddressArray.indexOf(aDistrict);
    if (indexToRemove > -1) {
      formatttedAddressArray.splice(indexToRemove);
    }
    indexToRemove = formatttedAddressArray.indexOf(aLocality);
    if (indexToRemove > -1) {
      formatttedAddressArray.splice(indexToRemove);
    }
    aStreetName = formatttedAddressArray.join(', ');
    if (event.name) {
      aStreetName = event.name + ', ' + aStreetName;
    }
  } else {
    // console.log('formatted address not present');
    aStreetName = (
      addressCompoments['premise'] +
      addressCompoments['street_number'] +
      addressCompoments['route'] +
      addressCompoments['sub_locality_level_3'] +
      addressCompoments['sub_locality_level_2'] +
      addressCompoments['sub_locality_level_1']
    ).replace(/^\s+|\s+$/g, '');
    // if(aDistrict !== '') {
    //   aStreetName = aStreetName + ', ' + aDistrict;
    // }
  }

  let map_locality_street = '';
  if (event.name !== '') {
    map_locality_street = event.name;
  }
  if (event.vicinity !== '') {
    map_locality_street = map_locality_street + ', ' + event.vicinity;
  }
  if (map_locality_street === '') {
    map_locality_street = aStreetName;
  }

  const result = {
    streetName: aStreetName,
    street: aStreetName,
    vicinity: event.vicinity,
    map_locality_street: map_locality_street,
    city: aCity,
    state_cd: aState,
    state: aState,
    state_short: addressCompoments['state_short'],
    pincode: aPincode,
    location_lat: aLat,
    location_long: aLong,
  };
  // console.log(result);
  return result;
}
export function getFormattedDate(value: Date, aFormat = 'dd MMM hh:mm a') {
  if (value) {
    const aDate = value instanceof Date ? value : new Date(value);
    let datePipe = new DatePipe('en-IN');
    return new DatePipe('en-IN').transform(value, aFormat);
  }
  return null;
}
export function getMonthName(value?: Date) {
  if (!value) {
    value = new Date();
  }
  const aDate = value instanceof Date ? value : new Date(value);
  let datePipe = new DatePipe('en-IN');
  return new DatePipe('en-IN').transform(value, 'MMM');
}
export function getYearName(value?: Date) {
  if (!value) {
    value = new Date();
  }
  const aDate = value instanceof Date ? value : new Date(value);
  let datePipe = new DatePipe('en-IN');
  return new DatePipe('en-IN').transform(value, 'YYYY');
}
export function getPreviousMonthName(value?: Date) {
  if (!value) {
    value = new Date();
  }
  const aDate = value instanceof Date ? value : new Date(value);
  const currentMonth = aDate.getMonth();
  let nextMonth = currentMonth - 1;
  if (nextMonth == 0) {
    nextMonth = 12;
  }
  aDate.setMonth(nextMonth);
  let datePipe = new DatePipe('en-IN');
  return new DatePipe('en-IN').transform(value, 'MMM');
}
export function isEarlier(timeStamp: any, now: any = null) {
  if (now == null) {
    now = new Date();
  }
  if (now instanceof String) {
    now = new Date(now + '');
  }
  return timeStamp < now;
}
export function isToday(timeStamp: any, now: any = null) {
  if (now == null) {
    now = new Date();
  }
  if (now instanceof String) {
    now = new Date(now + '');
  } else if (now instanceof Date) {
    now = now;
  }
  const val1 = now.setHours(0, 0, 0, 0);

  if (timeStamp instanceof Date) {
    // const timeStamp2 = new Date(timeStamp);
    const val2 = timeStamp.setHours(0, 0, 0, 0);
    if (val1 == val2) {
      return true;
    }
    // return (now == timeStamp2);
  } else {
    //  console.log('time stamp is not date ' + timeStamp);
  }
  return false;
}
export function safeDate(obj: any, json: any, field: any) {
  // console.log(field);
  if (!json[field]) {
    return null;
  } else if (json[field] == '0000-00-00 00:00:00') {
    return null;
  }
  if (json[field] != null && typeof json[field] === 'string') {
    let trimmed = json[field].trim();
    if (trimmed.includes('  ') || trimmed.length <= 19) {
      obj[field] = new Date(trimmed.replace(/-/g, '/'));
    } else {
      obj[field] = new Date(trimmed);
    }
  } else {
    obj[field] = json[field];
  }
  return obj[field];
  // console.log(obj[field]);
}
export function getSafeDate(json: any, field: any) {
  let result = null;
  if (!json[field]) {
    return result;
  } else if (json[field] == '0000-00-00 00:00:00') {
    return null;
  }
  if (json[field] != null && typeof json[field] === 'string') {
    let trimmed = json[field].trim();
    if (trimmed.includes('  ') || trimmed.length <= 19) {
      result = new Date(trimmed.replace(/-/g, '/'));
    } else {
      result = new Date(trimmed);
    }
  } else {
    result = json[field];
  }
  return result;
}
export const dateFormats = {
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  dateOnlyFormat: 'DD/MM/YYYY',
  monthYearFormat: 'MM/YYYY',
  isoFormat: 'YYYY-MM-DD HH:mm:SS ', // Corresponds to '2016-01-04T13:00:00Z'
  isoFormat8601: 'YYYY-MM-DDThh:mm:ss.sTZD',
};

export function toDate(
  value: string,
  /**
   * The default has *all* the formats
   *  - This is because strict date type validations
   *  - are done by passing in explicit limited sets.
   **/
  formats = [
    dateFormats.dateTimeFormat,
    dateFormats.dateOnlyFormat,
    dateFormats.isoFormat,
    dateFormats.monthYearFormat,
  ]
): { valid: boolean; date: Date | null } {
  if (!value || !value.trim().length) {
    return { valid: true, date: null };
  }
  let trimmed = value.trim();
  if (!formats.some((format) => format.length == trimmed.length)) {
    return { valid: false, date: null };
  }
  return { valid: false, date: null };
  // http://momentjs.com/docs/#/parsing/string-formats/
  // let date = moment(value, formats, true);
  // if (!date.isValid()) {
  //   return { valid: false, date: null };
  // }
  // return { valid: true, date: date.toDate() };
}
export function timeSince(
  timeStamp: any,
  now: any = null,
  approx = false,
  gorem = true
) {
  //  return timeDifference(new Date(), timeStamp);
  let result = '';

  if (now == null) {
    now = new Date();
  }

  let secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;
  let past = false;
  let agodue = '';
  // console.log(timeStamp);
  // console.log(secondsPast);
  if (isEarlier(timeStamp, now)) {
    past = true;
    agodue = ' ago';
  }
  if (gorem == false) {
    agodue = '';
  }
  if (secondsPast < 0) {
    secondsPast = Math.abs(secondsPast);
  }
  if (secondsPast < 60) {
    result = Math.round(secondsPast) + ' secs' + agodue;
  } else if (secondsPast < 3600) {
    result = Math.round(secondsPast / 60) + ' mins' + agodue;
  } else if (secondsPast <= 86400) {
    let minutesAgo = Math.round(
      (secondsPast / 3600 - Math.floor(secondsPast / 3600)) * 60
    );
    result =
      Math.round(secondsPast / 3600) + ' hrs ' + minutesAgo + ' mins' + agodue;
    if (approx) {
      result = Math.round(secondsPast / 3600) + ' hrs ' + agodue;
    }
  } else if (secondsPast > 86400) {
    Math.round(secondsPast / 86400) + ' days ' + agodue;
    if (approx) {
      result = Math.round(secondsPast / 86400) + ' days ' + agodue;
    }
  } else if (secondsPast > 86400 * 7) {
    let day = timeStamp.getDate();
    let month = timeStamp
      .toDateString()
      .match(/ [a-zA-Z]*/)[0]
      .replace(' ', '');
    let year =
      timeStamp.getFullYear() == now.getFullYear()
        ? ''
        : ' ' + timeStamp.getFullYear();

    result =
      day +
      ' ' +
      month +
      year +
      ' ' +
      timeStamp.getHours() +
      ':' +
      timeStamp.getMinutes();
  }
  return result;
}

export function timeDifference(current: any, previous: any) {
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;
  let elapsed = current.getTime() - previous.getTime();
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' secs';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' mins';
  } else {
    let hoursRoot = Math.floor(elapsed / msPerHour);
    let minsRoot = Math.round((elapsed - hoursRoot * msPerHour) / msPerMinute);
    return Math.round(elapsed / msPerHour) + ' hrs ' + minsRoot + ' mins';
  }
}

export function calculateDistance(
  lat1: number,
  long1: number,
  lat2: number,
  long2: number
) {
  let p = 0.017453292519943295; // Math.PI / 180
  let c = Math.cos;
  let a =
    0.5 -
    c((lat1 - lat2) * p) / 2 +
    (c(lat2 * p) * c(lat1 * p) * (1 - c((long1 - long2) * p))) / 2;
  let dis = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  return Math.round(dis);
}
export function replaceAppendObjectInArray(
  aData: any,
  myArray: any,
  aKey = 'id'
) {
  let aDataFound = false;
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][aKey] === aData[aKey]) {
      myArray[i] = aData;
      aDataFound = true;
      // console.log('found by key  replaced ' + aKey + '  '  + aData[aKey]);
      return true;
    }
  }
  if (!aDataFound) {
    // console.log('NOT found by key adding ' + aKey + '  '  + aData[aKey]);
    myArray.push(aData);
    return true;
  }
  return false;
}
export function searchInObjectArray(idKey: any, myArray: any) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].id === idKey) {
      return myArray[i];
    }
  }
  return false;
}
export function searchInObjectArrayByName(nameKey: any, myArray: any) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].name === nameKey) {
      return myArray[i];
    }
  }
  return false;
}
export function searchInObjectArrayByField(
  nameField: any,
  nameKey: any,
  myArray: any
) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][nameField] === nameKey) {
      return myArray[i];
    }
  }
  return false;
}
export function compare(a: any, b: any, nameField: any) {
  if (a[nameField] < b[nameField]) {
    return -1;
  }
  if (a[nameField] > b[nameField]) {
    return 1;
  }
  return 0;
}
export function searchInObjectArrayByDDKey(nameKey: any, myArray: any) {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].dd_key === nameKey) {
      if (myArray[i].dd_value_short) {
        return myArray[i].dd_value_short;
      } else {
        return myArray[i].dd_value;
      }
    }
  }

  return nameKey;
}
export function getTruckTypeValue(key: any) {
  const value = getDropDownValue('truck_type_cd', key);
  return value;
}
export function getDropDownList(dd_name: any) {
  const listOfValues = Constants.DROP_DOWN_HASH[dd_name.toUpperCase()];
  // console.log(listOfValues);
  return listOfValues;
}
export function getDropDownValue(dd_name: any, dd_key: any) {
  if (dd_key && dd_key !== null) {
    dd_key = dd_key.toString();
    const listOfValues = Constants.DROP_DOWN_HASH[dd_name.toUpperCase()];
    if (listOfValues) {
      return searchInObjectArrayByDDKey(dd_key, listOfValues);
    }
  }
  return dd_key;
}
export function getDropDownShortValue(dd_name: any, dd_key: any) {
  if (dd_key && dd_key !== null) {
    dd_key = dd_key.toString();
    const listOfValues = Constants.DROP_DOWN_HASH[dd_name.toUpperCase()];
    if (listOfValues) {
      return searchInObjectArrayByDDKey(dd_key, listOfValues);
    }
  }
  return dd_key;
}
export async function readFileAsText(file: any) {
  let result = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsText(file);
  });

  // console.log(result); // aGV5IHRoZXJl...

  return result;
}
export function getDisplayErrorList(anErrorList: any) {
  let errMsg: any;
  // console.log(anErrorList);
  if (typeof anErrorList === 'string' || anErrorList instanceof String) {
    errMsg = anErrorList;
  } else if (Array.isArray(anErrorList)) {
    errMsg = Object.keys(anErrorList).map((it: any) => anErrorList[it]);
  } else if (typeof anErrorList === 'object') {
    // errMsg = Object.values(error.error.errors);
    errMsg = Object.keys(anErrorList).map((it) => anErrorList[it]);
  } else {
    errMsg = JSON.stringify(anErrorList, getCircularReplacer());
  }
  return errMsg;
}
export function getDisplayErrorMessage(error: any) {
  console.log(error);
  // console.log(typeof error);
  // console.log(error.constructor.name);
  const userGlasswing = StorageService.getItem('userGlasswing');
  let userString = '';
  if (userGlasswing && userGlasswing != null) {
    userString =
      userGlasswing.id +
      ', ' +
      userGlasswing.name +
      ', ' +
      userGlasswing.email +
      +', ' +
      userGlasswing.work_area_cd;
  }

  let userAgent = {
    language: '',
    platform: '',
    userAgent: '',
    connectionDownlink: '',
    connectionEffectiveType: '',
    location: StorageService.getLoc(),
    userGlasswing: userString,
  };
  // if (window.navigator) {
  //   userAgent.language = window.navigator.language;
  //   userAgent.platform = window.navigator.platform;
  //   userAgent.userAgent = window.navigator.userAgent;
  //   if (window.navigator['connection']) {
  //     userAgent.connectionDownlink = window.navigator['connection'].downlink;
  //     userAgent.connectionEffectiveType =
  //       window.navigator['connection'].effectiveType;
  //   }
  // }

  //  let stackTrace = JSON.stringify(error);
  let stackTrace = JSON.stringify(error, getCircularReplacer());

  let errMsg: any = '';
  if (!window.navigator.onLine) {
    errMsg = 'No Internet Connection';
  } else if (typeof error === 'string' || error instanceof String) {
    // console.log('error is string ' + error);
    errMsg = <string>error;
  } else if (stackTrace == '' || stackTrace == '{}') {
    //  console.log('stacktrace is empty ' );
    errMsg = stackTrace = error.toString();
  } else if (error instanceof HttpErrorResponse) {
    if (!navigator.onLine) {
      errMsg = 'No Internet Connection';
    } else if (error.error instanceof Blob) {
      //  console.log('blob error')
      const errCopy = error;
      const reader: FileReader = new FileReader();
      const obs = new Observable((observer: any) => {
        reader.onloadend = (e) => {
          // console.log(reader.result);
          const messageObject = JSON.parse(reader.result as string);
          //  console.log(messageObject);
          // console.log(typeof messageObject.errors)
          observer.error({
            error: {
              message: messageObject.message,
            },
            message: messageObject.message,
            errors: messageObject.errors,
            data: messageObject.data,
            status: errCopy.status,
          });
          observer.complete();
          // return errMsg1;
        };
      });
      obs.subscribe((error) => {
        //  console.log(error);
      });
      reader.readAsText(error.error);
      // return;
      // return obs;
    } else {
      if (error.status == 0) {
        errMsg = 'Unable to contact server';
      } else if (error.status == 422) {
        if (error.error && error.error.errors) {
          errMsg = getDisplayErrorList(error.error.errors);
        } else {
          errMsg = JSON.stringify(error, getCircularReplacer());
        }
      } else if (typeof error.error !== 'undefined') {
        if (typeof error.error.errors !== 'undefined') {
          errMsg = getDisplayErrorList(error.error.errors);
        } else if (typeof error.error.error !== 'undefined') {
          if (typeof error.error.error.message !== 'undefined') {
            errMsg = error.error.error.message;
          } else {
            errMsg = JSON.stringify(error.error.error, getCircularReplacer());
          }
        } else if (typeof error.error.message !== 'undefined') {
          errMsg = error.error.message;
          if (error.error.data !== 'undefined') {
            errMsg = errMsg + ' ' + error.error.data;
          }
        } else {
          errMsg = JSON.stringify(error.error, getCircularReplacer());
        }
      } else {
        errMsg = JSON.stringify(error, getCircularReplacer());
      }
    }
  } else if (error instanceof Response) {
    const body = error.json() || '';
    errMsg = JSON.stringify(body, getCircularReplacer()); // JSON.stringify(body);
  } else if (error instanceof TypeError) {
    let errCopy = error;
    delete errCopy.stack;
    errMsg = JSON.stringify(errCopy, getCircularReplacer()); // JSON.stringify(errCopy);
  } else if (error instanceof Error) {
    errMsg = JSON.stringify(error, getCircularReplacer()); //, false, 2); //JSON.stringify(error);

    return '';
  } else if (error.errors && error.errors !== 'undefined') {
    // console.log('here');
    errMsg = getDisplayErrorList(error.errors);
  } else if (
    error.error &&
    error.error.data &&
    error.error.data !== 'undefined'
  ) {
    // console.log('here2');
    errMsg = error.error.data;
  } else if (error.data && error.data !== 'undefined') {
    // console.log('here3');
    errMsg = error.data;
  } else if (Array.isArray(error)) {
    errMsg = error;
  } else {
    // console.log('here4');
    errMsg = JSON.stringify(error, getCircularReplacer()); //, false, 2); //JSON.stringify(error);
  }
  let errTxt = '';
  if (error.status) {
    errTxt = error.status + ' ' + errTxt;
  }
  if (error.statusText) {
    errTxt = errTxt + error.statusText + ' ';
  } else if (error.message) {
    errTxt = errTxt + error.message + ' ';
  }
  errTxt = errTxt.trim();
  // const body = JSON.stringify () // inspect({ ts: Date.now(), userAgent, stackTrace }, false, 2); // JSON.stringify({ts: Date.now(), userAgent, stackTrace});
  // console.log(errTxt);
  console.log(errMsg);
  let result = '';
  if (errTxt !== '') {
    result = '<p>' + errTxt + '</p>';
  }
  if (typeof errMsg === 'string' || errMsg instanceof String) {
    result = result + <string>errMsg;
  } else if (Array.isArray(errMsg)) {
    result = result + '<ul>';
    console.log('is array error msg');
    errMsg.forEach((element) => {
      if (Array.isArray(element)) {
        console.log('element is array');
        element.forEach((subElement) => {
          console.log('type of sub element');
          console.log(typeof subElement);
          if (typeof subElement === 'string' || subElement instanceof String) {
            result = result + '<li>' + subElement + '</li>';
          } else if (Array.isArray(subElement)) {
            console.log('sibelement is array');
            subElement.forEach((subSubElement) => {
              result = result + '<li>' + subSubElement + '</li>';
            });
          } else if (typeof subElement === 'object') {
            console.log('sibelement is object');
            Object.keys(subElement).map((it) => {
              result = result + '<li>' + subElement[it] + '</li>';
            });
          } else {
            result = result + '<li>' + JSON.stringify(subElement) + '</li>';
          }
        });
      } else {
        result = result + '<li>' + element + '</li>';
      }
    });
    result = result + '</ul>';
  } else if (typeof errMsg === 'object') {
    console.log('is object');
    result = result + '<ul>';
    Object.keys(errMsg).map((it) => {
      result = result + '<li>' + errMsg[it] + '</li>';
    });
    result = result + '</ul>';
  }

  return result;
}
export function getStringBeforeComma(str: any) {
  if (str && typeof str == 'string') {
    const len = str.indexOf(',');
    if (len > 0) {
      return str.substring(0, len);
    }
  }

  return str;
}
export function getFirstWordMax15(str: any) {
  str = str.replace('LIMITED', '');
  str = str.replace('LTD', '');
  str = str.replace('PVT LTD', '');
  str = str.replace('INDUSTRIES', '');

  if (str.length < 15) {
    return str;
  }
  let spaceIndex = str.indexOf(' ');
  if (spaceIndex > 3) {
    return str.substring(0, spaceIndex);
  } else {
    return (
      str.substring(0, spaceIndex) +
      str.substring(spaceIndex, str.indexOf(' ', spaceIndex))
    );
  }
}
export function tConvertToAMPM(time: any) {
  if (time && time !== null) {
    // console.log(time);
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    // console.log(time);
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  return time;
}
export function checkTermNotInList(
  searchTerm: string,
  aList: string | any[],
  aField: string,
  aFieldLevel1 = ''
) {
  let result = true;
  // console.log(aList);
  for (let i = 0; i < aList.length; ++i) {
    const element = aList[i];
    let fieldValue = '';
    let fieldValueSearc = searchTerm;
    if (aFieldLevel1 && aFieldLevel1 != null && aFieldLevel1 !== '') {
      fieldValue = element[aFieldLevel1][aField];
    } else {
      fieldValue = element[aField];
    }
    if (fieldValue) {
      fieldValue = fieldValue + '';
    }
    // console.log(fieldValue);
    // console.log(searchTerm);

    if (fieldValue.indexOf(searchTerm) !== -1) {
      result = false;
      break;
    }
  }
  return result;
}
function getListInObject(obj: any, aList?: any, getList?: any) {
  let newList = aList; // [];

  if (typeof aList === 'string') {
    newList = obj[aList];
  } else if (typeof aList === 'object') {
    newList = aList;
  }
  if (getList) {
    newList = obj[getList]();
  }
  // console.log(typeof aList);
  // console.log(newList);
  // console.log(aList);
  return newList;
}
export function observeChanges(
  obj: { [x: string]: (arg0: any) => void },
  aControl: AbstractControl,
  aList: any,
  aServiceFn: string | number,
  aSetFn: string | number,
  aField = '',
  aFieldLevel1 = '',
  aCheckFn?: any,
  resetFn?: any,
  getList?: any
) {
  const anObserver = aControl.valueChanges.pipe(
    debounceTime(500),
    filter((value) => {
      console.log('in value pipe ' + value);
      if (aControl.dirty && resetFn) {
        // console.log('field is dirty using reset function ' + resetFn + value);

        obj[resetFn](value);
      }
      if (aCheckFn) {
        return obj[aCheckFn](value);
      } else {
        if (!aControl.valid) {
          return false;
        }
        value = value + '';

        let result =
          value != null && aControl.dirty && value && value.length > 2;

        result = typeof value === 'string';
        // console.log(value);
        console.log('check for string ' + result);
        if (value === '[object Object]') {
          result = false;
          console.log('value is an object coerced as string');
        }
        // console.log(obj);
        // console.log(aList);
        let newList = getListInObject(obj, aList, getList); // [];

        // if(typeof aList === 'string' ) {
        //   newList = obj[aList];
        // }
        // else if(typeof aList === 'object') {
        //   newList = aList;
        // }
        // // console.log('new list1');
        // // console.log(typeof newList);
        // if (getList) {
        //   newList = obj[getList]();
        // }
        // console.log(typeof aList);
        // console.log(aList);
        // console.log('new list');
        // console.log(typeof newList);
        // console.log(newList);
        if (result && newList.length > 0 && aField !== '') {
          // console.log('checking item note in list ' + aField + ' ' + value);
          result = checkTermNotInList(value, newList, aField, aFieldLevel1);
        }
        console.log(result);
        return result;
      }
    }),
    distinctUntilChanged(),
    switchMap(async (searchTerm) => obj[aServiceFn](searchTerm))
  );
  return anObserver.subscribe(
    (response) => {
      console.log(response);
      obj[aSetFn](response);
      // console.log(getListInObject(obj, aList, getList ));
      // if (aClass) {
      //   aList = aClass.toArray(response.data);
      // } else {
      //   aList = response.data;
      // }
      // console.log(aList);
      // return aList;
    },
    (error) => {
      console.log(getDisplayErrorMessage(error));
      if (obj['showErrorDialog']) {
        obj['showErrorDialog'](error);
      }
    }
  );
}
/**
 * Derives file name from the http response
 * by looking inside content-disposition
 * @param res http Response
 */
export function getFileNameFromResponseContentDisposition(res: Response) {
  // if (res.headers) {
  //   const contentDisposition = res.headers.get('content-disposition') || '';
  //   const matches = /filename=([^;]+)/gi.exec(contentDisposition);
  //   const fileName = (matches[1] || 'untitled').trim();
  //   return fileName;
  // }
}
export function createAndDownloadBlobFile(
  body: BlobPart,
  options: BlobPropertyBag | undefined,
  filename: string
) {
  // if (body.type && body.type == 'application/json') {
  //   alert(JSON.stringify(body, getCircularReplacer()));
  //   // return;
  // }
  // const blob = new Blob([body], options);
  // if (navigator.msSaveBlob) {
  //   // IE 10+
  //   navigator.msSaveBlob(blob, filename);
  // } else {
  //   const link = document.createElement('a');
  //   // Browsers that support HTML5 download attribute
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute('href', url);
  //     link.setAttribute('download', filename);
  //     link.style.visibility = 'hidden';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: object | null) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function removeEmpty(obj: { [x: string]: any }) {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k] && obj[k] != null && obj[k] != '';
    })
    .reduce(function (acc: any, k) {
      acc[k] = obj[k];
      return acc;
    }, {});
}
export function transformMoney(value: number, args: string[]): any {
  if (!isNaN(value)) {
    let currencySymbol = '₹';
    if (value == null) {
      return '';
    }
    let InrRSOut = value;
    InrRSOut = Math.round(InrRSOut);
    let RV = '';
    if (InrRSOut > 0 && InrRSOut < 1000) {
      RV = InrRSOut.toString();
    } else if (InrRSOut >= 1000 && InrRSOut < 10000) {
      RV = InrRSOut.toString();
    } else if (InrRSOut >= 10000 && InrRSOut < 100000) {
      let f1 = InrRSOut.toString().substring(0, 2);
      let f2 = InrRSOut.toString().substring(2, 5);
      RV = f1 + ',' + f2;
    } else if (InrRSOut >= 100000 && InrRSOut < 1000000) {
      let f1 = InrRSOut.toString().substring(0, 1);
      let f2 = InrRSOut.toString().substring(1, 3);
      if (f2 == '00') {
        RV = f1 + ' Lacs';
      } else {
        RV = f1 + '.' + f2 + ' Lacs';
      }
    } else if (InrRSOut >= 1000000 && InrRSOut < 10000000) {
      let f1 = InrRSOut.toString().substring(0, 2);
      let f2 = InrRSOut.toString().substring(2, 4);
      if (f2 == '00') {
        RV = f1 + ' Lacs';
      } else {
        RV = f1 + '.' + f2 + ' Lacs';
      }
    } else if (InrRSOut >= 10000000 && InrRSOut < 100000000) {
      let f1 = InrRSOut.toString().substring(0, 1);
      let f2 = InrRSOut.toString().substring(1, 3);
      if (f2 == '00') {
        RV = f1 + ' Cr';
      } else {
        RV = f1 + '.' + f2 + ' Cr';
      }
    } else if (InrRSOut >= 100000000 && InrRSOut < 1000000000) {
      let f1 = InrRSOut.toString().substring(0, 2);
      let f2 = InrRSOut.toString().substring(2, 4);
      if (f2 == '00') {
        RV = f1 + ' Cr';
      } else {
        RV = f1 + '.' + f2 + ' Cr';
      }
    } else if (InrRSOut >= 1000000000 && InrRSOut < 10000000000) {
      let f1 = InrRSOut.toString().substring(0, 3);
      let f2 = InrRSOut.toString().substring(3, 5);
      if (f2 == '00') {
        RV = f1 + ' Cr';
      } else {
        RV = f1 + '.' + f2 + ' Cr';
      }
    } else if (InrRSOut >= 10000000000) {
      let f1 = InrRSOut.toString().substring(0, 4);
      let f2 = InrRSOut.toString().substring(6, 8);
      if (f2 == '00') {
        RV = f1 + ' Cr';
      } else {
        RV = f1 + '.' + f2 + ' Cr';
      }
    } else {
      RV = InrRSOut.toString();
    }
    return currencySymbol + RV;
  }
}
// export function showErrorDialog(error){
//     const _dialogService = instantiateDialogService();
//     _dialogService.openAlert({
//       title: Error, message: getDisplayErrorMessage(error)
//     });
// }

export class ADataSource extends MatTableDataSource<any> {
  // data: any;
  constructor(aData?: any[]) {
    super(aData);
    // this.data = aData;
  }
  override connect(): BehaviorSubject<any[]> {
    console.log('adata connect');
    let aReturn = super.connect();
    console.log('adata connect2');
    return aReturn;
  }
  getIndex(id: any) {
    super.connect();
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id == id) {
        return index;
      }
    }
    return -1;
  }
  remove(id: any) {
    const itemIndex = this.getIndex(id);
    //  console.log('found ' + id + ' at ' + itemIndex);
    this.data = this.data.splice(itemIndex, 1);
    //  console.log('removed');
  }
  override disconnect() {
    console.log('adata disconnect');
    super.disconnect();
    console.log('adata disconnect2');
  }
}
