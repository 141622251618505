<header id="header" class="header fixed-header">
  <div [ngClass]="!showSearch ? 'header-form-register' : 'header-form'">
    <div class="hamburger-menu" (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </div>
    <div class="form-f-div" *ngIf="showSearch">
      <form [formGroup]="searchForm" class="form">
        <div class="container">
          <div class="row header-div">
            <div class="col-lg-2 col-md-12 col-sm-12 d-flex pt-2">
              <div class="logo-text">Digital Freight Network</div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 d-flex pt-2">
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <mat-form-field
                  class="transfer-ipt"
                  appearance="fill"
                  class="w80"
                >
                  <input
                    matInput
                    placeholder="Load From"
                    formControlName="source_city"
                    (blur)="submitSearch()"
                  />
                </mat-form-field>
              </div>
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <mat-form-field
                  class="transfer-ipt"
                  appearance="fill"
                  class="w80"
                >
                  <input
                    matInput
                    placeholder="Load To"
                    formControlName="destination_city"
                    (blur)="submitSearch()"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 d-flex pt-2">
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <app-dropdowns
                  [placeholder]="'Truck Type'"
                  [type]="'truck_type_cd'"
                  [selected]="searchForm.value.truck_type_cd"
                  (onSelect)="settruckTypesCd($event)"
                  [valid]="searchForm.get('truck_type_cd')!!.valid"
                  (touched)="searchForm.get('truck_type_cd')!!.markAsTouched()"
                  class="w80"
                  id="header"
                ></app-dropdowns>
              </div>
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <button mat-button class="search-btn" (click)="submitSearch()">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="driver-register-header" *ngIf="!showSearch">
    <div>Digital Freight Network</div>
  </div>
</header>
