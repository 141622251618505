<div class="">
  <div class="row map-div">
    <div class="col-lg-5 col-md-12 col-sm-12 first-div">
      <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        (mapReady)="mapReady($event)"
      >
        <!-- <agm-marker [latitude]="lat" [longitude]="lng"> </agm-marker> -->
        <agm-marker-cluster
          *ngIf="!showPath"
          [maxZoom]="7"
          [zoomOnClick]="true"
          [imagePath]="'assets/img/m'"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.location_lat"
            [longitude]="m.location_long"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [opacity]="m.opacity"
            [iconUrl]="'/assets/img/truck_icon2.png'"
          >
            <!-- [iconUrl]="'/assets/img/truck_icon.png'"
              [iconHeight]="20"
              [iconWidth]="20" -->
            <!-- [animation]="'BOUNCE'" -->
            <agm-info-window>
              <ul>
                <li>{{ m.name }} {{ m.registration_no }}</li>
                <li>{{ m.location }}</li>
                <li>{{ m.updated_at | date: "short" }}</li>
                <li *ngIf="m.trip_no">
                  {{ m.trips_status_cd }} {{ m.trip_origin_city }}
                  {{ m.trip_destination_city }}
                </li>
                <li *ngIf="m.trip_no">
                  <a [routerLink]="['/trips/map/trip', m.trips_id]"
                    >View Trip {{ m.trip_no }}</a
                  >
                </li>
                <li *ngIf="m.registration_no">
                  <a [routerLink]="['/users/map/truck', m.trucks_id]"
                    >View Truck {{ m.registration_no }}</a
                  >
                </li>
                <li *ngIf="m.users_id">
                  <a [routerLink]="['/users/map/user', m.users_id]"
                    >View User {{ m.name }}</a
                  >
                </li>
              </ul>
            </agm-info-window>
          </agm-marker>
        </agm-marker-cluster>
        <!-- <agm-direction
          *ngIf="showPath"
          [origin]="origin"
          [destination]="destination"
          (onResponse)="onDirectionResponse($event)"
          (sendInfowindow)="obtainInfowindow($event)"
          (onChange)="onDirectionChange($event)"
        >
        </agm-direction> -->

        <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng"
             [radius]="5000"
             [fillColor]="'red'"
             [circleDraggable]="true"
             [editable]="true">
         </agm-circle> -->
      </agm-map>
    </div>
    <div class="col-lg-7 col-md-12 col-sm-12">
      <div class="popular-lanes truck-types" *ngIf="!firstSearch">
        <div class="truck-types-heading">
          <h2>Trucks from</h2>
          <!-- <div
            class="reset-search d-flex justify-content-center"
            *ngIf="firstSearch"
            (click)="back()"
          >
            <mat-icon class="mr-2">arrow_back</mat-icon>Back
          </div> -->
        </div>
        <ul>
          <li
            class="list"
            matRipple
            *ngFor="let data of truckTypesDetails"
            (click)="setTruckFrom(data)"
          >
            <div class="row w-100">
              <div [ngClass]="firstSearch ? 'col-4' : 'col-5'">
                {{ data.source_city }}
                <span *ngIf="data.destination_city"
                  >- {{ data.destination_city }}</span
                >
              </div>
              <div
                *ngIf="firstSearch"
                [ngClass]="firstSearch ? 'col-3' : 'col-5'"
              >
                {{ getTruckTypeValue(data.truck_type_cd) }}
              </div>
              <div [ngClass]="firstSearch ? 'col-3' : 'col-5'">
                {{ data.trips_count }} Trucks
              </div>
              <div [ngClass]="firstSearch ? 'col-2' : 'col-2'">
                <mat-icon>arrow_circle_right</mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="popular-lanes" *ngIf="!finalStep && !secondSearch">
        <div class="d-flex justify-content-between t-o-p-l">
          <h2>Trucks on Popular lanes</h2>
          <div
            class="reset-search d-flex justify-content-center"
            *ngIf="firstSearch"
            (click)="back()"
          >
            <div class="icon">
              <mat-icon class="mr-2">arrow_back</mat-icon>
              <div>Back</div>
            </div>
          </div>
        </div>
        <ul>
          <li
            class="list"
            matRipple
            *ngFor="let data of popularLanesDetails"
            (click)="goToPopularLanes(data)"
          >
            <div class="row w-100">
              <div class="col-5">
                {{ data.source_city }} - {{ data.destination_city }}
              </div>
              <div class="col-5">{{ data.trips_count }} Trucks</div>
              <div class="col-2"><mat-icon>arrow_circle_right</mat-icon></div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="popular-lanes truck-types"
        *ngIf="firstSearch && !finalStep && !secondSearch"
      >
        <div class="truck-types-heading">
          <h2>Truck Types from</h2>
        </div>
        <ul>
          <li
            class="list"
            matRipple
            *ngFor="let data of truckTypesDetails"
            (click)="selectTruckType(data)"
          >
            <div class="row w-100">
              <div [ngClass]="firstSearch ? 'col-4' : 'col-5'">
                {{ data.source_city }}
                <span *ngIf="data.destination_city"
                  >- {{ data.destination_city }}</span
                >
              </div>
              <div
                *ngIf="firstSearch"
                [ngClass]="firstSearch ? 'col-3' : 'col-5'"
              >
                {{ getTruckTypeValue(data.truck_type_cd) }}
              </div>
              <div [ngClass]="firstSearch ? 'col-3' : 'col-5'">
                {{ data.trips_count }} Trucks
              </div>
              <div [ngClass]="firstSearch ? 'col-2' : 'col-2'">
                <mat-icon>arrow_circle_right</mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ng-container *ngIf="firstSearch && secondSearch">
        <div class="available-reaching">
          <div class="popular-lanes truck-types">
            <div class="truck-available-heading">
              <h2>Trucks Currently Available</h2>
              <div
                class="reset-search d-flex justify-content-center"
                *ngIf="firstSearch"
                (click)="back()"
              >
                <mat-icon class="mr-2">arrow_back</mat-icon>
                <div>Back</div>
              </div>
            </div>
            <ul>
              <li
                class="list"
                matRipple
                *ngFor="let data of truckTypesDetails"
                (click)="selectTruckType(data)"
              >
                <div class="row w-100">
                  <div [ngClass]="firstSearch ? 'col-4' : 'col-5'">
                    {{ data.source_city }}
                    <span *ngIf="data.destination_city"
                      >- {{ data.destination_city }}</span
                    >
                  </div>
                  <div
                    *ngIf="firstSearch"
                    [ngClass]="firstSearch ? 'col-3' : 'col-5'"
                  >
                    {{ getTruckTypeValue(data.truck_type_cd) }}
                  </div>
                  <div [ngClass]="firstSearch ? 'col-3' : 'col-5'">
                    {{ data.registration_no }}
                  </div>
                  <!-- <div [ngClass]="firstSearch ? 'col-2' : 'col-2'">
                    <mat-icon>arrow_circle_right</mat-icon>
                  </div> -->

                </div>
                <div class="row">
                  <div class="col-12 w-100 d-flex justify-content-end">
                    <button
                      mat-button
                      class="call-now"
                      (click)="openCallDetailDialog()"
                    >
                      Call Now
                    </button>
                  </div>
                </div>

              </li>
            </ul>
            <!-- <ul>
              <li class="list" matRipple>
                <div class="row w-100">
                  <div class="col-12 w-100 d-flex">
                    <div class="col-4">
                      Mumbai
                      <span>- Delhi</span>
                    </div>
                    <div class="col-4">UP 02 TR 1345</div>
                    <div class="col-4">65,000 - 75,000</div>
                  </div>
                  <div class="col-12 truck-available-details w-100 pt-3 pb-3">
                    <div class="truck-type-details">
                      Truck Type : 7 T - 20 FT SXL
                    </div>
                    <div class="at-location">Availability : At location</div>
                  </div>
                  <div class="col-12 w-100 d-flex justify-content-end">
                    <button
                      mat-button
                      class="call-now"
                      (click)="openCallDetailDialog()"
                    >
                      Call Now
                    </button>
                  </div>
                </div>
              </li>
            </ul> -->
          </div>
          <!-- <div class="popular-lanes truck-types">
            <div class="truck-available-heading">
              <h2>Trucks Reaching Destination</h2>
            </div>
            <ul>
              <li class="list" matRipple>
                <div class="row w-100">
                  <div class="col-12 w-100 d-flex">
                    <div class="col-4">
                      Mumbai
                      <span>- Delhi</span>
                    </div>
                    <div class="col-4">UP 02 TR 1345</div>
                    <div class="col-4">65,000 - 75,000</div>
                  </div>
                  <div class="col-12 truck-available-details w-100 pt-3 pb-3">
                    <div class="truck-type-details">
                      Truck Type : 7 T - 20 FT SXL
                    </div>
                    <div class="at-location">Availability : 1 Day</div>
                  </div>
                  <div class="col-12 w-100 d-flex justify-content-end">
                    <button
                      mat-button
                      class="call-now"
                      (click)="openCallDetailDialog()"
                    >
                      Call Now
                    </button>
                  </div>
                </div>
              </li>
              <li class="list" matRipple>
                <div class="row w-100">
                  <div class="col-12 w-100 d-flex">
                    <div class="col-4">
                      Mumbai
                      <span>- Delhi</span>
                    </div>
                    <div class="col-4">UP 02 TR 1345</div>
                    <div class="col-4">65,000 - 75,000</div>
                  </div>
                  <div class="col-12 truck-available-details w-100 pt-3 pb-3">
                    <div class="truck-type-details">
                      Truck Type : 7 T - 20 FT SXL
                    </div>
                    <div class="at-location">Availability : 1 Day</div>
                  </div>
                  <div class="col-12 w-100 d-flex justify-content-end">
                    <button
                      mat-button
                      class="call-now"
                      (click)="openCallDetailDialog()"
                    >
                      Call Now
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </ng-container>
      <div class="search-result" style="display: none">
        <div class="truck-types-heading">
          <h2>Search Result</h2>
          <div
            class="reset-search d-flex justify-content-center"
            *ngIf="firstSearch"
            (click)="resetSearch()"
          >
            Reset Search <mat-icon class="ml-1">refresh</mat-icon>
          </div>
        </div>
        <div class="content">
          <div class="search-details">
            <div>
              {{ formDetails.source_city }} - {{ formDetails.destination_city }}
            </div>
            <div>{{ formDetails.truck_type_cd }}</div>
            <div>{{ formDetails.trips_count }} Trucks Available</div>
          </div>
          <div class="rate-details">
            <div>Rate: 35000-55000</div>
            <div>Availability in: 1-2 days</div>
          </div>
          <div class="action-btn">
            <div>
              No.of Trucks Required-
              <input
                type="number"
                min="0"
                class="inpt"
                [(ngModel)]="numberOfTrucks"
              />
            </div>
            <div>
              <button
                mat-button
                (click)="openDialog()"
                [disabled]="numberOfTrucks === 0 ? 'true' : 'false'"
                [ngClass]="numberOfTrucks === 0 ? 'disabled' : ''"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
