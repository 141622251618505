import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './layout/header/header.component';
import { SharedModule } from './shared/shared.module';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';

import { MapDetailComponent } from './main/map-detail/map-detail.component';
import { DatePipe } from '@angular/common';
import { AppPreloadingStrategy } from './core/appPreloadingStrategy';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { AuthenticatorService } from './core/services/authenticator.service';
import { CommonService } from './core/services/common.service';
import { ConfigService } from './core/services/config.service';
import { ErrorsHandlerService } from './core/services/errors.handler.service';
import { HttpService } from './core/services/http-service.service';
import { StorageService } from './core/services/storage.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalGuard,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { HttpAuthInterceptor } from './core/services/http-auth-interceptor';
import {
  RollbarService,
  rollbarFactory,
} from './core/services/rollbar.service';
import { FooterComponent } from './layout/footer/footer.component';
import { AlertDialogComponent } from './layout/dialogs/alert-dialog/alert-dialog.component';
import { DialogService } from './layout/dialogs/services/dialog.service';
import { DropdownsService } from './core/services/dropdowns.service';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { OAuthSettings } from './core/msaloauth';
import { BookNowDialog } from './layout/dialogs/book-now-dialog/book-now.modal';
import { DropdownsModule } from './layout/dropdowns/dropdowns.module';
import { RegisterDriverComponent } from './main/register-driver/register-driver.component';
import { CallDetailsModal } from './layout/dialogs/call-details/call-details.modal';
import { LeftMenuComponent } from './layout/left-menu/left-menu.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MapDetailComponent,
    FooterComponent,
    AlertDialogComponent,
    BookNowDialog,
    CallDetailsModal,
    RegisterDriverComponent,
    LeftMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyBZiXc5g-7L9C4lwh3rt1yVJQ7lK_Z-K7k',
      apiKey: 'AIzaSyAMlJziycOojUxfu0q6EQGRr8A5h2Eujns',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    AgmMarkerClustererModule,
    DropdownsModule,
  ],
  providers: [
    // Title,
    CommonService,
    HttpService,
    DropdownsService,
    StorageService,
    ErrorsHandlerService,
    ConfigService,
    DialogService,
    AuthGuard,
    RoleGuard,
    DatePipe,
    AuthenticatorService,
    // EchoService,
    AppPreloadingStrategy,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize_config,
      deps: [ConfigService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorsHandlerService },
    // {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    // { provide: LOCALE_ID, useValue: 'en-in'},
    // { provide: MAT_DATE_LOCALE, useValue: 'en-in'},
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  entryComponents: [AlertDialogComponent, BookNowDialog, CallDetailsModal],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function initialize_config(configService: ConfigService) {
  console.log('app module init config');
  return () => configService.initializeConfig();
}
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment.
      clientId: OAuthSettings.appId,
      authority:
        'https://login.microsoftonline.com/' + OAuthSettings.tenentId + '/',
      redirectUri: '/user/login',
      // postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: false,
      },
    },
  });
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}
